

@media (max-width: 1599px) { 
    .banner .overflow-y-auto {
     
        height: calc(100vh - 50px);
    }
}
@media (max-width: 767.98px) { 
.banner .overflow-y-auto {
    height: auto;
    height: cals(100%-50px);
}
}