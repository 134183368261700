
.student-profile .bg-section {
    background: linear-gradient(180deg, #B70022 35%, rgba(255, 255, 255, 1) 35%);
}
.social-icon li a {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(204 77 29 / 40%);
    border-radius: 50%;
}
.social-icon li a svg {
    color: #CC4D1D;
    height: 15px;
    width: 15px;
}
.profile-section:focus-visible {
    outline: 0px;
    border: 0px;
}