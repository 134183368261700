.search {
  border-radius: 0px 0.375rem 0.375rem 0px;
}

.highlight-btn {
  box-shadow: rgb(42 121 189 / 50%) 0px 4px 16px,
    rgb(42 121 189 / 0%) 0px 8px 32px;
  border: none !important;
}

.highlight-btn a:hover {
  border: none !important;
}

.small-sidebar {
  background: #b70022;
}

.small-sidebar svg {
  width: 35px;
  height: 35px;
}

.small-sidebar .full-logo {
  display: none;
}

.small-logo {
  display: none;
}

.sidebar-head {
  background: #fff;
}

.small-sidebar img.small-logo {
  margin: 0 auto;
  text-align: center;
  display: block;
}

.overflow-y-auto {
  overflow-y: scroll;
  /* height: calc(100vh - 208px); */
}
.table-responsive tr td:nth-child(even), .table-responsive tr th:nth-child(even) {
  padding: 0px;
}
.table-responsive tr td:last-child, .table-responsive tr th:last-child {
  padding-right: 16px;
}
.custom-shadow-cer {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
}
@media screen and (max-width: 767px) {
  .aside {
    width: 100%;
  }

  /* .overflow-y-auto {
    height: calc(100vh - 152px);
  } */
}

@media screen and (max-width: 575px) {
  .filter-custom {
    flex: 0 0 100%;
}
.filter {
  height: auto;
  padding: 10px;
}
.filter-custom .dropdown, .filter-custom .dropdown button {
  width: 100%;
}
.filter-custom .dropdown button {
  margin-top: 10px;
}
  /* .overflow-y-auto {
    height: calc(100vh - 144px);
  } */
}

.sidebar {
  border-right: 1px solid #eee;
  /* height: 100vh; */
  background: linear-gradient(360deg,
      rgba(204, 77, 29, 1) 22%,
      rgba(183, 0, 34, 1) 90%);
  box-shadow: 0px 0px 10px #eee;

}

.list {
  height: calc(100vh - 82px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 10px 15px 15px;
  overflow-y: scroll;
}

.list a {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
}

.list a svg path {
  stroke: #fff !important;
}

.list a:hover svg path {
  stroke: #fff !important;
}

.list ul li {
  margin: 10px 0px;
}

.list ul li:last-child {
  margin: 0px;
}

.sidebar .list a {
  background-color: transparent;
}
.sidebar .list  ul li {
  font-size: 14px;
}

.sidebar .list a:hover {
  background-color: #fff;
  color: #b70022;
  border-radius: 4px;

}

.sidebar .list a:hover svg path {
  stroke: #b70022 !important;
}
.list ul li:hover div {
  background: #fff;
  border-radius: 5px;
  color: #b70022;
}
.active_link {
  background-color: #fff !important;
  color: #b70022;
 border-radius: 4px;
}

.list .active_link svg path {
  stroke: #b70022 !important;
}

.active_link-new {
  background-color: #2977ba;
  color: #fff;
}

.hover-min-width:hover {
  background-color: #2977ba;
  color: #fff;
}

.sidebar .list a:active {
  box-shadow: rgb(42 121 189 / 50%) 0px 4px 16px,
    rgb(42 121 189 / 0%) 0px 8px 32px;
}

.sidebar-inner {
  /* height: 100vh; */
  transition: 1s ease-in-out;
}

.highlight-min-width {
  height: 56px;
}

.notification-sec {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  z-index: 9;
}
.table-responsive {
  width: 100%;
  overflow-x: scroll;
}
@media screen and (max-width: 1023px) {
  .sidebar {
    position: absolute;
    width: 0px;
    left: 0px;
    background: #b70022;
    box-shadow: 0px 0px 10px #eee;
    top: 0;
    border-radius: 0;
    z-index: 999;
  }

  .sidebar-inner {
    background: #b70022;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 0;
    z-index: 999;
    width: 260px;
  }

  .sidebar-inner h1 {
    justify-content: start;
    margin-left: 0;
    padding: 0px;
  }

  .sidebar-head {
    padding: 0px 10px;
  }

  .sidebar-head img {
    margin: 0px;
  }
}