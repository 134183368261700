.banner {
  height: 100vh;
}
.right-section {
  background: linear-gradient(
    to right,
    #fff 50%,
    rgba(204, 77, 29, 1) 22%,
    rgba(183, 0, 34, 1) 90%
  );
  height: 100%;
  position: relative;
}
/* .right-section:after {
    content: "";
    position: absolute;
    right: 0;
    background-image: url("../../../images//shape-img.png");
    height: 100%;
    width: 50%;
    background-size: 93%;
    background-repeat: no-repeat;
    background-position: center right;
    background-position: 100% 160%;
    z-index: 1;
  } */
.right-section img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
  border-radius: 20px;
  z-index: 99;
}
.banner form input:focus {
  outline: none;
}
.custum-border {
  border: 1px solid #b1b1b1;
}
.login-btn {
  background: linear-gradient(
    360deg,
    rgba(204, 77, 29, 1) 22%,
    rgba(183, 0, 34, 1) 90%
  );
}

@media screen and (max-width: 1440px) {
  .banner .right-section {
    height: 660px;
  }
}
@media screen and (max-width: 1366px) {
  .banner .banner {
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .banner .right-section {
    height: 600px;
  }
}
@media screen and (max-width: 767px) {
  .banner .right-section {
    height: 550px;
  }
}
@media screen and (max-width: 575px) {
  .banner .right-section {
    height: 360px;
  }
}
