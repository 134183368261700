/* .course-mangement {
  height: 750px;
  overflow-y: auto;
} */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  .course-mangement::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  .course-mangement::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  .course-mangement::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  }
  
  @media screen and (max-width: 1903px) {
    /* .course-mangement {
      height: 640px;
    } */
  }
  @media screen and (max-width: 1023px) {
      /* .course-mangement {
        height: auto;
        overflow-y: auto;
      } */
  }
  