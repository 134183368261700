.popup {
  height: 90%;
  overflow-y: scroll;
  border-radius: 20px;
}
.profile-instuctor {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
}
.profile-instuctor img {
  width: 100%;
  max-width: 120px;
  border-radius: 10px;
}

.rating-button {
  background-color: #ccc;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.rating-button:hover {
  background-color: #999;
}

.rating-button.selected {
  background-color: #4caf50; /* You can change this to your preferred color */
}

@media (max-width: 1699px) {
  .table-responsive tr td,
  .table-responsive tr th {
    padding: 16px !important;
  }
}
