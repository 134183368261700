.login-pop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.certificate_left_area {
    clip-path: polygon(0% 0%, 100% 0%, 100% 94%, 50% 100%, 0 94%);
    height: 90%;
  }
  
@media (max-width: 991.98px) { 
.login-pop {
    
    width: 100%;
    max-width: 90%;
}
}
/* @media (max-width: 767px) { 
.login-pop {
    position: relative;
    top: inherit;
    left: inherit;
    transform: none;
}
} */