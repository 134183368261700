.dashboard-topbar-grid {
    border-bottom: 2px solid #b70022;
  }
  .bg-gradient {
    background: linear-gradient(
      360deg,
      rgba(204, 77, 29, 1) 22%,
      rgba(183, 0, 34, 1) 90%
    );
  }
  .custom-shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  